.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Display */
.display-flex { display: flex; }
.justify-end { justify-content: end; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-center { justify-content: center; }
.align-center { align-items: center; }
.flex-direction-column { flex-direction: column; }

/* Width */
.w-20 { width: 20% !important; }
.w-25 { width: 25% !important; }
.w-50 { width: 50% !important; }
.w-75 { width: 75% !important; }
.w-100 { width: 100% !important; }

/* height */
.h-20 { height: 20% !important; }
.h-25 { height: 25% !important; }
.h-50 { height: 50% !important; }
.h-75 { height: 75% !important; }
.h-100 { height: 100% !important; }

/* Cursor pointer */
.cursor { cursor: pointer; }

/* Font family */
.ff { font-family: ui-sans-serif; }

/* text color */
.color-red { color: red; }
.App-color { color: #a95431; }

/* text alignment */
.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }

/* margin */
.m-tb-2 { margin: 2px 0px; }
.m-tb-10 { margin: 10px 0px; }
.m-a-10 { margin: 10px; }
.m-l-5 { margin-right: 5px;}


/* border */
.border { border: 1px solid gray;}

/* Padding */
.p-0 { padding: 0px; }

